import { useAutoTranslation } from "@/entities";
import { LANG_KEYS } from "@/shared/constants";
import { QRCodeSVG } from "qrcode.react";
import { useState } from "react";
import { getAppStoreLink } from "@/shared/utils";

const storeLink = getAppStoreLink();

export const QrToMobileApp = () => {
  const { t } = useAutoTranslation();
  const [isShown, setIsShown] = useState(true);

  if (!isShown) return null;

  return (
    <div className="flex flex-col items-center bg-white rounded-lg p-4 shadow-lg w-fit fixed bottom-4 right-4 z-50">
      <div className="flex justify-end w-full">
        <button
          type="button"
          onClick={() => setIsShown(false)}
          className="text-3xl cursor-pointer w-fit h-fit -translate-y-3 translate-x-2"
          style={{
            lineHeight: "0.7",
          }}
        >
          &times;
        </button>
      </div>
      <QRCodeSVG value={storeLink} size={200} />
      <a
        href={storeLink}
        target="_blank"
        className="text-black text-lg font-medium mt-3 underline"
      >
        {t(LANG_KEYS.DOWNLOAD_THE_APP.key, LANG_KEYS.DOWNLOAD_THE_APP.value)}
      </a>
    </div>
  );
};
