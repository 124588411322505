import { BASIC_INFO } from "../constants/basic-info";

/**
 * Detects if the user is on an iOS or Android device and returns the appropriate app store link
 * @returns The app store link based on device, or both links if device can't be determined
 */
export const getAppStoreLink = () => {
  // Check if we're in a browser environment
  if (typeof navigator === "undefined" || typeof window === "undefined") {
    return BASIC_INFO.GOOGLE_PLAY_APP_LINK;
  }

  const userAgent = navigator.userAgent.toLowerCase();

  // iOS detection - checks for iPhone, iPad, iPod
  const isIOS = /iphone|ipad|ipod/.test(userAgent);

  // Android detection
  const isAndroid = /android/.test(userAgent);

  if (isIOS) {
    return BASIC_INFO.IOS_APP_LINK;
  } else if (isAndroid) {
    return BASIC_INFO.GOOGLE_PLAY_APP_LINK;
  }

  // If device type couldn't be determined, return null
  return BASIC_INFO.GOOGLE_PLAY_APP_LINK;
};

/**
 * Checks if the user is on a mobile device (iOS or Android)
 * @returns Boolean indicating if user is on a mobile device
 */
export const isMobileDevice = () => {
  if (typeof navigator === "undefined" || typeof window === "undefined") {
    return false;
  }

  const userAgent = navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod|android/.test(userAgent);
};
